import React from 'react';
import { graphql } from 'gatsby';

import { HTMLContent } from '../components/content';
import Header from '../components/header';
import InfoList from '../components/infoList';

const Map = ({ pageContext, data, location }) => {
  const { current: info, other: more } = data;

  return (
    <article className="page info">
      <Header
        title={info.frontmatter.title}
        back="/mapas"
        intro={info.frontmatter.intro}
        path={location.pathname}
      />

      <HTMLContent content={info.html} />

      {more.edges.length ? (
        <>
          <h2>Máis mapas</h2>
          <InfoList
            showIntro={true}
            showFuzzyCount={true}
            info={more.edges}
            className="with-description"
          />
        </>
      ) : null}
    </article>
  );
};

export default Map;

export const query = graphql`
  query($slug: String!) {
    current: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        intro
      }
      html
    }
    other: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/mapas/" }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            title
            intro
          }
        }
      }
    }
  }
`;
